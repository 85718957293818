// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-card-index-js": () => import("./../../../src/pages/card/index.js" /* webpackChunkName: "component---src-pages-card-index-js" */),
  "component---src-pages-contato-index-js": () => import("./../../../src/pages/contato/index.js" /* webpackChunkName: "component---src-pages-contato-index-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manager-index-js": () => import("./../../../src/pages/manager/index.js" /* webpackChunkName: "component---src-pages-manager-index-js" */),
  "component---src-pages-pay-index-js": () => import("./../../../src/pages/pay/index.js" /* webpackChunkName: "component---src-pages-pay-index-js" */)
}

